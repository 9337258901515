<template>
	<v-app>
		<v-navigation-drawer app class="tranches-navigation" v-if="$vuetify.breakpoint.smAndDown" v-model="drawer" :width="300" clipped>
			<v-list class="pa-0">
				<v-list-item class="tranches-list-item px-8" v-for="(item, i) in menuItems"
							 :value="i"
							 :key="i"
							 :href="item.href">
					<v-list-item-title v-text="item.text"></v-list-item-title>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		
		<v-app-bar height="52"
				   elevation="0"
				   fixed
				   class="flex-grow-0"
				   dark
				   color="primary lighten-1">
			<v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="drawer = !drawer"></v-app-bar-nav-icon>
			
			<v-list class="pa-0 tranches-menu" v-if="$vuetify.breakpoint.mdAndUp">
				<v-list-item class="tranches-list-item px-8" v-for="(item, i) in menuItems"
							 :value="i"
							 :key="i"
							 :href="item.href">
					<v-list-item-title v-text="item.text"></v-list-item-title>
				</v-list-item>
			</v-list>
			
			<v-spacer></v-spacer>
			
			<v-menu bottom
					nudge-bottom="20"
					max-width="250"
					offset-y
					v-if="isInitialized && $vuetify.breakpoint.mdAndUp"
					content-class="elevation-5 menu-transition">
				<template v-slot:activator="{ on, attrs }">
					<v-btn min-width="300"
						   class="text-none profile-btn text-left justify-end user-menu pr-2 pl-5 text-subtitle-2 white--text"
						   style="border: none"
						   v-ripple="false"
						   v-bind="attrs"
						   v-on="on" text outlined>
						<span class="text-truncate">
							{{ user.profile.title }}
						</span>
						<frp-icon class="ml-2" src="ico_bod-chevron-down" :color="colors.grey.lighten4"></frp-icon>
						<frp-icon class="ml-2" src="ico_help" :color="colors.warning.base"></frp-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="logout">
						<v-icon :color="colors.primary.lighten1">mdi-logout</v-icon>
						<v-list-item-title class="ml-2">{{ $t("buttons.logout") }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		
		<v-main>
			<v-container v-if="isInitialized"
						 fluid
						 class="pt-13 pb-16 px-0 primary lighten-4 full-height flex-nowrap align-start flex-column justify-start"
						 fill-height>
				<template v-if="pageMode === PageModeType.OK">
<!--					TODO: доделать хб как найдется решение со вставкой ссылки в хб -->
<!--					<tranches-breadcrumbs :namespace="storeManager.tranches.breadcrumbs.namespace"></tranches-breadcrumbs>-->
					<router-view :key="$route.meta.key || $route.name"/>
				</template>
				<frp-page-not-found v-else-if="pageMode === PageModeType.PAGE_NOT_FOUND"></frp-page-not-found>
				<frp-access-denied-error v-else-if="pageMode === PageModeType.ACCESS_DENIED"></frp-access-denied-error>
				<frp-alerts left-offset="0"></frp-alerts>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import TranchesBreadcrumbs from "@/components/common/TranchesBreadcrumbs.vue";
import { externalUrls } from "@/constants/tranches/externalUrls";
import { RouteNames } from "@/router/tranches/routes";
import { formatFullName } from "@/utils/formatting";
import FrpAlerts from "Components/alerts/FrpAlerts";
import { i18n } from "Plugins/index";
import { PageModeType } from "Store/tranches/types/pageModeType";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import FrpAccessDeniedError from "Views/errors/FrpAccessDeniedError";
import storeManager from "Store/manager";
import rootTypes from "Store/tranches/types";
import { mapState, mapMutations } from "vuex";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpIcon from "Components/icon/FrpIcon";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import SecurityService from "Services/auth/securityService";
import { actionTypes, namespace } from "Store/tranches/modules/user/types";
import { createNamespacedHelpers } from "vuex";

const securityService = new SecurityService();
const { mapState: mapUserState, mapActions: mapUserActions } = createNamespacedHelpers(namespace);

const tranchesBreadcrumbsHelpers = createNamespacedHelpers(storeManager.tranches.breadcrumbs.namespace);

export default {
	mixins: [colorsMixin, authorizationMixin],
	metaInfo: {
		title: i18n.t("metaTitles.bankAccountsRegister")
	},
	data() {
		return {
			drawer: false,
			menuItems: [
				{
					text: this.$t("navigation.help"),
					permissions: [],
					href: externalUrls.help
				},
				{
					text: this.$t("navigation.otherModules"),
					permissions: [],
					href: `${process.env.VUE_APP_OLK_BASE_URL}/apps`
				}
			],
			storeManager,
			PageModeType,
			RouteNames,
			formatFullName
		};
	},
	computed: {
		...mapState({
						pageMode: state => state.pageMode
					}),
		...mapUserState({
							isInitialized: state => state.isInitialized,
							user: state => state.user
						}),
		...tranchesBreadcrumbsHelpers.mapState({
												   breadcrumbs: state => state.items,
												   isLoading: state => state.isLoading
											   })
	},
	methods: {
		...mapMutations({
							resetPageMode: rootTypes.mutationTypes.RESET_PAGE_MODE
						}),
		...mapUserActions({
							  initializeStore: actionTypes.initialize
						  }),
		logout() {
			this.signOut();
		}
	},
	async created() {
		if(!this.isInitialized)
			await this.initializeStore();
	},
	watch: {
		"$route"(to, from) {
			if(!to || !from)
				return;
			
			if(to.name !== from.name)
				this.resetPageMode();
		}
	},
	components: {
		TranchesBreadcrumbs,
		FrpAlerts,
		FrpPageNotFound,
		FrpAccessDeniedError,
		FrpBtn,
		FrpIcon
	}
};
</script>

<style scoped lang="scss">
.tranches-menu {
  display: flex;
  background: none;
  gap: 24px;

  a {
	padding: 0 20px !important;
  }

  a::before {
	opacity: 0;
  }

  .v-list-item {
	min-height: 36px !important;
  }
}

.tranches-navigation {
  background-color: var(--v-white-base) !important;
}

.tranches-list-item {
  .v-list-item__title {
	font-size: 0.875rem !important;
	font-family: "Ubuntu", sans-serif !important;
	letter-spacing: 0.0015em !important;
  }
}
</style>
