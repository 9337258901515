<template>
	<frp-dialog :value="value"
				v-if="value"
				:title="title"
				max-width="550"
				persistent
				@update:value="onDialogValueUpdated">
		<template #content="{ onIntersect }" v-if="text">
			<div v-mutate="onIntersect" class="d-flex flex-column" style="row-gap: 16px">
				<span>{{ text }}</span>
			</div>
		</template>

		<template #footer>
			<frp-btn elevation="0" :color="colors.grey.lighten3" dark
					 @click="handleClose">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0" color="blue" dark
					 :loading="loading"
					 @click="$emit('submit')">
				{{ btnText }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import { RouteNames } from "@/router/tranches/routes";
import { TrancheConfirmDialogTypeEnum } from "@/types/tranches/TrancheConfirmDialogTypeEnum";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatCurrency } from "@/utils/formatting";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/tranches/modules/application/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		type: {
			type: [String, TrancheConfirmDialogTypeEnum],
			required: true
		},
		value: Boolean,
		loading: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapGetters({
			currentDialogTranche: getterTypes.currentDialogTranche
		}),
		title() {
			const { plannedDate, amount } = this.currentDialogTranche ?? {};
			const formattedDate = formatDate(plannedDate, dateFormat);
			const formattedAmount = formatCurrency(amount, 0);
			
			return this.$t(`dialogs.trancheConfirmDialog.${this.type}.title`, { date: formattedDate, amount: formattedAmount });
		},
		text() {
			return this.$t(`dialogs.trancheConfirmDialog.${this.type}.text`);
		},
		btnText() {
			return this.$t(`dialogs.trancheConfirmDialog.${this.type}.submitBtn`);
		}
	},
	methods: {
		onDialogValueUpdated(value) {
			if(!value)
				this.handleClose();
		},
		handleClose() {
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpDialog,
		FrpBtn
	}
};
</script>
