import { namespace, actionTypes, mutationTypes, getterTypes } from "@/store/tranches/modules/application/types";
import AbortService from "@/services/abortService";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import { cloneDeep, difference, orderBy } from "lodash";
import TranchesTrancheApplicationState from "@/store/tranches/modules/application/types/tranchesTrancheApplicationState";
import router from "@/router/tranches";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { TranchesController } from "@/api/tranches";
import { ProjectInfoHelper } from "@/store/tranches/modules/application/types/projectInfo";
import { resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import SubscribersManager from "@/store/manager/subscribersManager";
import { TrancheScheduleModeTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleModeTypeEnum";
import { TrancheSchedule, TrancheScheduleHelper } from "@/store/tranches/modules/application/types/trancheSchedule";
import { TrancheScheduleTranche, TrancheScheduleTrancheHelper } from "@/store/tranches/modules/application/types/trancheScheduleTranche";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { TrancheScheduleStatusTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleStatusTypeEnum";
import ListingMixinBuilder from "@/store/shared/listing";
import SortingMixinBuilder from "@/store/shared/sorting";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { TrancheScheduleSnapshotKeysEnum } from "@/store/tranches/modules/application/types/TrancheScheduleSnapshotKeysEnum";
import { i18n } from "@/plugins";
import PermissionsService from "@/services/permissionsService";
import { Permissions } from "@/constants/permissions";
import { externalUrls } from "@/constants/tranches/externalUrls";
import { SortingOrderTypeEnum } from "@/constants/SortingOrderTypeEnum";
import { ApiTrancheStatusTypeEnum } from "@/api/tranches/types/ApiTrancheStatusTypeEnum";
import { ApiUpdateTrancheScheduleRequestItemMapper } from "@/api/tranches/types/apiUpdateTrancheScheduleRequestItem";
import { TrancheScheduleHistoryMapper } from "@/types/tranches/trancheScheduleHistory";
import { findClosestDateByPeriod, formatDate } from "@/utils/dates";
import ApiFile from "@/api/types/storage/apiFile";
import { LoanStorageController } from "@/api/loanStorage";
import { ApiTrancheScheduleDocument } from "@/api/tranches/types/apiTrancheScheduleDocument";
import contentDispositionParser from "content-disposition-parser";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { ExpertiseController } from "@/api/expertise";
import { ExpertiseHelper } from "@/types/expertise/expertise";
import { ExpertiseSubTypeHelper } from "@/types/expertise/expertiseSubType";
import { ExpertiseTypeHelper } from "@/types/expertise/expertiseType";
import { ExpertiseTypeEnum } from "@/types/expertise/ExpertiseTypeEnum";
import BadRequestException from "@/exceptions/badRequestException";
import { dateFormat } from "@/utils/formats";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";

const abortService = new AbortService();
const tranchesController = new TranchesController(abortService);
const loanStorageController = new LoanStorageController(abortService);
const expertiseController = new ExpertiseController(abortService);
const permissionsService = new PermissionsService();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: TrancheScheduleSnapshotKeysEnum.TRANCHES,
			fields: ["trancheSchedule.tranches", "editableItems"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new TranchesTrancheApplicationState(
			new ListingModel<TrancheScheduleTranche>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.ascending
			}),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

let subscribersManager: SubscribersManager<TranchesTrancheApplicationState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<TranchesTrancheApplicationState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<TranchesTrancheApplicationState>(rootState, namespace);
	
	switch (mutation.type) {
		case resolveMutation(namespace, mutationTypes.SET_MODE):
		{
			const items = state.trancheSchedule.tranches;
			
			if(mutation.payload === TrancheScheduleModeTypeEnum.EDIT) {
				subscribersManager.commit(resolveMutation(namespace, mutationTypes.SET_EDITABLE_ITEMS), cloneDeep(items));
				subscribersManager.commit(resolveMutation(namespace, mutationTypes.SET_STATE_SNAPSHOT),
					TrancheScheduleSnapshotKeysEnum.TRANCHES);
				
				if(!state.trancheSchedule.tranches.length) {
					const emptyItem = TrancheScheduleTrancheHelper.getEmpty();
					
					subscribersManager.commit(resolveMutation(namespace, mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES), [emptyItem]);
					subscribersManager.commit(resolveMutation(namespace, mutationTypes.SET_EDITABLE_ITEMS), [emptyItem]);
				}
			}
			
			break;
		}
		default:
			break;
	}
};

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<TranchesTrancheApplicationState, any>>{
	...listingMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.isDraftTrancheScheduleStatus]: state => {
		return state.trancheSchedule.status === TrancheScheduleStatusTypeEnum.DRAFT;
	},
	[getterTypes.isPendingTrancheScheduleStatus]: state => {
		return state.trancheSchedule.status === TrancheScheduleStatusTypeEnum.PENDING;
	},
	[getterTypes.lkProjectLink]: state => {
		return `${externalUrls.lkProject}/${state.projectInfo.id}`;
	},
	[getterTypes.currentDialogTranche]: state => {
		return state.trancheSchedule.tranches.find(x => x.number === state.currentDialogTrancheNumber);
	},
	[getterTypes.formattedItems]: state => {
		return state.trancheSchedule.tranches.map(x => ({ ...x, key: `${x.index} ${x.amount} ${x.plannedDate}` }));
	}
};

const actions = <ActionTree<TranchesTrancheApplicationState, any>>{
	...baseMixin.actions,
	...listingMixin.actions,
	...sortingMixin.actions,
	...stateManipulationMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ rootState, dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		
		commit(mutationTypes.SET_APPLICATION_NUMBER, router.currentRoute.params.applicationNumber);
		
		await dispatch(actionTypes.getProjectInfo);
		
		if(state.projectInfo.id)
			await Promise.all([
				dispatch(actionTypes.updateListingItems),
				dispatch(actionTypes.fetchExpertises)
			]);
		
		Object.keys(TrancheScheduleSnapshotKeysEnum).forEach(key => {
			commit(mutationTypes.SET_STATE_SNAPSHOT, key);
		});
		
		const isUserCanCreateTrancheSchedule = await permissionsService.check([Permissions.TRANCHES_TRANCHE_SCHEDULE_UPDATE]);
		
		if(!state.trancheSchedule.tranches.length && isUserCanCreateTrancheSchedule) {
			const emptyItem = TrancheScheduleTrancheHelper.getEmpty();
			
			commit(mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES, [emptyItem]);
			commit(mutationTypes.SET_EDITABLE_ITEMS, [emptyItem]);
			commit(mutationTypes.SET_MODE, TrancheScheduleModeTypeEnum.EDIT);
		}
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.getProjectInfo]({ commit, state }) {
		commit(mutationTypes.SET_IS_PROJECT_INFO_FETCHING, true);
		
		try {
			const projectInfo = await tranchesController.getProjectInfo(state.applicationNumber);
			
			commit(mutationTypes.SET_PROJECT_INFO, ProjectInfoHelper.map(projectInfo));
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_PROJECT_INFO_FETCHING, false);
		}
	},
	async [actionTypes.updateListingItems]({ commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			const apiTrancheSchedule = await tranchesController.getTrancheSchedule(state.applicationNumber);
			
			const mappedTrancheSchedule = TrancheScheduleHelper.map(apiTrancheSchedule);
			
			const sortedAndReformattedTranches = orderBy(mappedTrancheSchedule.tranches, ["plannedDate"], [SortingOrderTypeEnum.ASC_ORDER])
			.map((x: TrancheScheduleTranche, index: number) => ({ ...x, index }));
			
			const formattedTrancheSchedule = {
				...mappedTrancheSchedule,
				tranches: sortedAndReformattedTranches
			} as TrancheSchedule;
			
			commit(mutationTypes.SET_TRANCHE_SCHEDULE, formattedTrancheSchedule);
			commit(mutationTypes.SET_LISTING_ITEMS, cloneDeep(formattedTrancheSchedule.tranches));
		} catch (error) {
			if(error.constructor === HttpNotFoundException)
				return;
			
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.addEditableItemTranche]({ commit, state }) {
		let newEmptyItem = TrancheScheduleTrancheHelper.getEmpty();
		
		const items = cloneDeep(state.editableItems);
		
		commit(mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES, [...items, { ...newEmptyItem, index: items.length }]);
		commit(mutationTypes.SET_EDITABLE_ITEMS, state.trancheSchedule.tranches);
	},
	async [actionTypes.deleteEditableItemTranche]({ commit, state }, index) {
		// удаляем по индексу айтем, потом мы проходим по всем editableItems и обновляем у всех в модели индекс, исходя из текущего индекса.
		let items = cloneDeep(state.editableItems);
		items.splice(index, 1);
		
		const mappedItems = items.map((x: TrancheScheduleTranche, index: number) => ({ ...x, index }));
		
		commit(mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES, mappedItems);
		commit(mutationTypes.SET_EDITABLE_ITEMS, mappedItems);
	},
	async [actionTypes.sendTrancheForApproval]({ commit, state, dispatch }, trancheNumber) {
		commit(mutationTypes.SET_SENDING_TO_APPROVAL_TRANCHE_NUMBER, trancheNumber);
		
		try {
			await tranchesController.sendTrancheForApproval(state.applicationNumber, String(trancheNumber));
			
			commit(mutationTypes.SET_TRANCHE_STATUS, { number: trancheNumber, value: ApiTrancheStatusTypeEnum.PENDING_APPROVAL });
			
			alertService.addInfo(AlertKeys.TRANCHE_SUCCESS_SENT_TO_APPROVAL);
			
			return true;
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
			return false;
		} finally {
			commit(mutationTypes.SET_SENDING_TO_APPROVAL_TRANCHE_NUMBER, undefined);
		}
	},
	async [actionTypes.fetchExpertises]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_EXPERTISES_LOADING, true);
		
		try {
			const {
				isAddExpertiseRight,
				items,
				spSubTypeExpertise,
				spTypeExpertise
			} = await expertiseController.getExpertises(ExpertiseTypeEnum.PRE, String(state.projectInfo.id));
			
			const formattedSubTypes = spSubTypeExpertise.map(x => ExpertiseSubTypeHelper.map(x));
			const filteredSubTypes = formattedSubTypes.filter(x => x.isTranches);
			
			const formattedItems = items.map(x => ExpertiseHelper.map(x));
			const filteredItems = formattedItems.filter(x => !x.isDelete && filteredSubTypes.some(y => y.id === x.expertiseSubtypeId));
			
			commit(mutationTypes.SET_EXPERTISES, filteredItems);
			commit(mutationTypes.SET_CAN_VIEW_EXPERTISES, !!isAddExpertiseRight);
			commit(mutationTypes.SET_EXPERTISE_TYPES, spTypeExpertise.map(x => ExpertiseTypeHelper.map(x)));
			commit(mutationTypes.SET_EXPERTISE_SUB_TYPES, filteredSubTypes);
		} catch (error) {
			if(error.constructor === AccessForbiddenException)
				return;
			
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_EXPERTISES_LOADING, false);
		}
	},
	async [actionTypes.tryCreateExpertise]({ commit, state, dispatch }, type: string) {
		commit(mutationTypes.SET_IS_EXPERTISE_CREATING, true);
		
		try {
			const { error, errorArr } = await expertiseController.createExpertise(ExpertiseTypeEnum.PRE,
				String(state.projectInfo.id),
				{ subTypeExpertiseCode: type });
			
			if(error)
				throw new BadRequestException("Ошибка", errorArr.join("\n"));
			
			await dispatch(actionTypes.fetchExpertises);
			
			alertService.addInfo(AlertKeys.EXPERTISE_SUCCESS_APPOINTED);
			
			return true;
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
			
			return false;
		} finally {
			commit(mutationTypes.SET_IS_EXPERTISE_CREATING, false);
		}
	},
	async [actionTypes.createOrderTranchePaymentApplication]({ commit, state }, { number, plannedDate }) {
		commit(mutationTypes.SET_IS_ORDER_TRANCHE_PAYMENT_APPLICATION_CREATING, true);
		commit(mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES_TRANCHE_ORDER_CREATING, { number, value: true });
		
		try {
			const { data, headers } = await tranchesController.orderTranchePaymentApplication(state.applicationNumber, number);
			
			const { filename } = contentDispositionParser(headers["content-disposition"]);
			
			commit(mutationTypes.SET_TRANCHE_ORDER_FILE, data);
			commit(mutationTypes.SET_TRANCHE_ORDER_FILENAME, filename);
			
			if(!state.trancheSchedule.tranches.find(x => x.number === number)!.orderDate) {
				commit(mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES_TRANCHE_ORDER_DATE, { number, date: Date.now() });
				
				alertService.addCustomInfo(i18n.t("alerts.info.tranchePaymentApplicationSuccessCreated",
					{ date: formatDate(plannedDate, dateFormat) }) as string);
			}
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_ORDER_TRANCHE_PAYMENT_APPLICATION_CREATING, false);
			commit(mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES_TRANCHE_ORDER_CREATING, { number, value: false });
		}
	},
	async [actionTypes.updateTrancheSchedule]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_TRANCHE_SCHEDULE_SAVING, true);
		
		try {
			const mappedEditableItems = state.editableItems.map(x => ApiUpdateTrancheScheduleRequestItemMapper.map(x));
			
			await tranchesController.updateTrancheSchedule(state.applicationNumber, mappedEditableItems);
			
			await dispatch(actionTypes.updateListingItems);
			
			Object.keys(TrancheScheduleSnapshotKeysEnum).forEach(key => {
				commit(mutationTypes.SET_STATE_SNAPSHOT, key);
			});
			
			alertService.addInfo(AlertKeys.TRANCHE_SCHEDULE_SUCCESS_UPDATED);
			commit(mutationTypes.SET_MODE, TrancheScheduleModeTypeEnum.READ);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			return error;
		} finally {
			commit(mutationTypes.SET_IS_TRANCHE_SCHEDULE_SAVING, false);
		}
	},
	async [actionTypes.acceptTranche]({ state, commit, dispatch }) {
		const trancheNumber = state.currentDialogTrancheNumber!;
		
		commit(mutationTypes.SET_ACCEPTING_TRANCHE_NUMBER, trancheNumber);
		
		try {
			await tranchesController.acceptTranche(state.applicationNumber, String(trancheNumber));
			
			commit(mutationTypes.SET_TRANCHE_STATUS, { number: trancheNumber, value: ApiTrancheStatusTypeEnum.ACCEPTED });
			
			alertService.addInfo(AlertKeys.TRANCHE_SUCCESS_ACCEPTED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_ACCEPTING_TRANCHE_NUMBER, undefined);
		}
	},
	async [actionTypes.confirmTranche]({ state, commit, dispatch }, trancheNumber) {
		commit(mutationTypes.SET_CONFIRMING_TRANCHE_NUMBER, trancheNumber);
		
		try {
			await tranchesController.confirmTranche(state.applicationNumber, String(trancheNumber));
			
			commit(mutationTypes.SET_TRANCHE_STATUS, { number: trancheNumber, value: ApiTrancheStatusTypeEnum.CONFIRMED });
			
			alertService.addInfo(AlertKeys.TRANCHE_SUCCESS_CONFIRMED);
			return true;
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			return false;
		} finally {
			commit(mutationTypes.SET_CONFIRMING_TRANCHE_NUMBER, trancheNumber);
		}
	},
	async [actionTypes.rejectTranche]({ state, commit, dispatch }, reason: string) {
		const trancheNumber = state.currentDialogTrancheNumber!;
		
		commit(mutationTypes.SET_REJECTING_TRANCHE_NUMBER, trancheNumber);
		
		try {
			await tranchesController.rejectTranche(state.applicationNumber, String(trancheNumber), reason);
			
			commit(mutationTypes.SET_TRANCHE_STATUS, { number: trancheNumber, value: ApiTrancheStatusTypeEnum.REJECTED });
			
			alertService.addInfo(AlertKeys.TRANCHE_SUCCESS_REJECTED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_REJECTING_TRANCHE_NUMBER, undefined);
		}
	},
	async [actionTypes.unacceptTranche]({ state, commit, dispatch }) {
		const trancheNumber = state.currentDialogTrancheNumber!;
		
		commit(mutationTypes.SET_UNACCEPTING_TRANCHE_NUMBER, trancheNumber);
		
		try {
			await tranchesController.unacceptTranche(state.applicationNumber, String(trancheNumber));
			
			commit(mutationTypes.SET_TRANCHE_STATUS, { number: trancheNumber, value: ApiTrancheStatusTypeEnum.PENDING_APPROVAL });
			commit(mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES_TRANCHE_ORDER_DATE, { number: trancheNumber, date: null });
			
			alertService.addInfo(AlertKeys.TRANCHE_SUCCESS_UNACCEPTED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_UNACCEPTING_TRANCHE_NUMBER, undefined);
		}
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchHistory]({ state, commit }) {
		const history = await tranchesController.getTrancheScheduleHistory(state.applicationNumber);
		
		commit(mutationTypes.SET_HISTORY, TrancheScheduleHistoryMapper.map(history));
	},
	async [actionTypes.addTrancheDocument]({ state, commit }, file: ApiFile) {
		commit(mutationTypes.SET_IS_DOCUMENT_UPLOADING, true);
		
		try {
			const trancheNumber = state.currentDialogTrancheNumber!;
			
			const meta = await loanStorageController.createTemperFile(file);
			
			const { document } = await tranchesController.addTrancheDocument({
				applicationNumber: +state.applicationNumber,
				trancheNumber: trancheNumber,
				tempFileId: meta.id
			});
			
			commit(mutationTypes.ADD_TRANCHE_DOCUMENT, { number: trancheNumber, value: document });
			
			alertService.addInfo(AlertKeys.DOCUMENT_SUCCESS_UPLOADED);
			
			return true;
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			return false;
		} finally {
			commit(mutationTypes.SET_IS_DOCUMENT_UPLOADING, false);
		}
	},
	async [actionTypes.deleteTrancheDocument]({ state, commit },
		{ trancheNumber, document }: { trancheNumber: number, document: ApiTrancheScheduleDocument })
	{
		commit(mutationTypes.ADD_DELETING_DOCUMENT_IDS, document.fileId);
		
		try {
			await tranchesController.deleteTrancheDocument(state.applicationNumber, String(trancheNumber), String(document.number));
			
			commit(mutationTypes.REMOVE_TRANCHE_DOCUMENT, { number: trancheNumber, id: document.fileId });
			
			alertService.addInfo(AlertKeys.DOCUMENT_SUCCESS_DELETED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.REMOVE_DELETING_DOCUMENT_IDS, document.fileId);
		}
	},
	async [actionTypes.updateTrancheAgreements]({ state, commit }, { trancheNumber, ids }: { trancheNumber: number, ids: number[] }) {
		commit(mutationTypes.SET_IS_TRANCHE_AGREEMENTS_SAVING, true);
		
		const tranche = state.trancheSchedule.tranches.find(x => x.number === trancheNumber)!;
		
		try {
			const [removedId] = difference(tranche.linkedCounterpartyAgreements, ids);
			const [addedId] = difference(ids, tranche.linkedCounterpartyAgreements);
			
			if(addedId) {
				await tranchesController.addTrancheAgreement(state.applicationNumber, String(trancheNumber), String(addedId));
				
				alertService.addInfo(AlertKeys.CONTRACT_SUCCESS_ADDED);
			}
			
			if(removedId) {
				await tranchesController.deleteTrancheAgreement(state.applicationNumber, String(trancheNumber), String(removedId));
				
				alertService.addInfo(AlertKeys.CONTRACT_SUCCESS_REMOVED);
			}
			
			commit(mutationTypes.SET_TRANCHE_AGREEMENT_IDS, { number: trancheNumber, value: ids });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_TRANCHE_AGREEMENTS_SAVING, false);
		}
	}
};

const mutations = <MutationTree<TranchesTrancheApplicationState>>{
	...baseMixin.mutations,
	...listingMixin.mutations,
	...sortingMixin.mutations,
	...stateManipulationMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_IS_TRANCHE_SCHEDULE_SAVING](state, value) {
		state.isTrancheScheduleSaving = value;
	},
	[mutationTypes.SET_IS_TRANCHE_SCHEDULE_ORDER_CONDITIONS_SAVING](state, value) {
		state.isTrancheScheduleOrderConditionsSaving = value;
	},
	[mutationTypes.SET_IS_TRANCHE_SCHEDULE_FORM_VALID](state, value) {
		state.isTrancheScheduleFormValid = value;
	},
	[mutationTypes.SET_SENDING_TO_APPROVAL_TRANCHE_NUMBER](state, value) {
		state.sendingToApprovalTrancheNumber = value;
	},
	[mutationTypes.SET_IS_ORDER_TRANCHE_PAYMENT_APPLICATION_CREATING](state, value) {
		state.isOrderTranchePaymentApplicationCreating = value;
	},
	[mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES_TRANCHE_ORDER_DATE](state, { number, date }) {
		state.trancheSchedule.tranches[state.trancheSchedule.tranches.findIndex(x => x.number === number)].orderDate = date;
	},
	[mutationTypes.SET_TRANCHE_ORDER_FILE](state, value) {
		state.trancheOrderFile = value;
	},
	[mutationTypes.SET_TRANCHE_ORDER_FILENAME](state, value) {
		state.trancheOrderFilename = value;
	},
	[mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES_TRANCHE_ORDER_CREATING](state, { number, value }) {
		state.trancheSchedule.tranches[state.trancheSchedule.tranches.findIndex(x => x.number === number)].orderCreating = value;
	},
	[mutationTypes.SET_TRANCHE_STATUS](state, { number, value }) {
		state.trancheSchedule.tranches[state.trancheSchedule.tranches.findIndex(x => x.number === number)].status = value;
	},
	[mutationTypes.SET_APPLICATION_NUMBER](state, value) {
		state.applicationNumber = value;
	},
	[mutationTypes.SET_IS_PROJECT_INFO_FETCHING](state, value) {
		state.isProjectInfoFetching = value;
	},
	[mutationTypes.SET_PROJECT_INFO](state, value) {
		state.projectInfo = value;
	},
	[mutationTypes.SET_TRANCHE_SCHEDULE](state, value) {
		state.trancheSchedule = cloneDeep(value);
	},
	[mutationTypes.SET_TRANCHE_SCHEDULE_TRANCHES](state, value) {
		state.trancheSchedule.tranches = cloneDeep(value);
	},
	[mutationTypes.SET_ACCEPTING_TRANCHE_NUMBER](state, value) {
		state.acceptingTrancheNumber = value;
	},
	[mutationTypes.SET_REJECTING_TRANCHE_NUMBER](state, value) {
		state.rejectingTrancheNumber = value;
	},
	[mutationTypes.SET_CONFIRMING_TRANCHE_NUMBER](state, value) {
		state.confirmingTrancheNumber = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.SET_EDITABLE_ITEMS](state, value) {
		state.editableItems = cloneDeep(value);
	},
	[mutationTypes.SET_TRANCHE_SCHEDULE_SUM](state, value) {
		state.trancheScheduleSum = value;
	},
	[mutationTypes.SET_IS_TRANCHE_SCHEDULE_UNSAVED_CHANGES_DIALOG_ENABLED](state, value) {
		state.isTrancheScheduleUnsavedChangesDialogEnabled = value;
	},
	[mutationTypes.SET_HISTORY](state, value) {
		state.history = value;
	},
	[mutationTypes.SET_CURRENT_DIALOG_TRANCHE_NUMBER](state, value) {
		state.currentDialogTrancheNumber = value;
	},
	[mutationTypes.SET_IS_DOCUMENT_UPLOADING](state, value) {
		state.isDocumentUploading = value;
	},
	[mutationTypes.ADD_TRANCHE_DOCUMENT](state, { number, value }) {
		const tranche = state.trancheSchedule.tranches.find(x => x.number === number)!;
		tranche.documents.push(cloneDeep(value));
	},
	[mutationTypes.REMOVE_TRANCHE_DOCUMENT](state, { number, id }) {
		const tranche = state.trancheSchedule.tranches.find(x => x.number === number)!;
		tranche.documents = tranche.documents.filter(x => x.fileId !== id);
	},
	[mutationTypes.ADD_DELETING_DOCUMENT_IDS](state, value) {
		state.deletingDocumentIds.push(value);
	},
	[mutationTypes.REMOVE_DELETING_DOCUMENT_IDS](state, value) {
		state.deletingDocumentIds = state.deletingDocumentIds.filter(x => x !== value);
	},
	[mutationTypes.SET_IS_TRANCHE_AGREEMENTS_SAVING](state, value) {
		state.isTrancheAgreementsSaving = value;
	},
	[mutationTypes.SET_UNACCEPTING_TRANCHE_NUMBER](state, value) {
		state.unacceptingTrancheNumber = value;
	},
	[mutationTypes.SET_TRANCHE_AGREEMENT_IDS](state, { number, value }) {
		const tranche = state.trancheSchedule.tranches.find(x => x.number === number)!;
		tranche.linkedCounterpartyAgreements = cloneDeep(value);
	},
	[mutationTypes.SET_EXPERTISES](state, value) {
		state.expertises = cloneDeep(value);
	},
	[mutationTypes.SET_CAN_VIEW_EXPERTISES](state, value) {
		state.canViewExpertises = value;
	},
	[mutationTypes.SET_IS_EXPERTISES_LOADING](state, value) {
		state.isExpertisesLoading = value;
	},
	[mutationTypes.SET_EXPERTISE_TYPES](state, value) {
		state.expertiseTypes = value;
	},
	[mutationTypes.SET_EXPERTISE_SUB_TYPES](state, value) {
		state.expertiseSubTypes = value;
	},
	[mutationTypes.SET_IS_EXPERTISE_CREATING](state, value) {
		state.isExpertiseCreating = value;
	}
};


export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const tranchesTrancheApplicationModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default tranchesTrancheApplicationModule;
