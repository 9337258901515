const lkBaseUrl = process.env.VUE_APP_LK_BASE_URL;
const loanBaseUrl = process.env.VUE_APP_LOAN_BASE_URL;

export const externalUrls = {
	lkProject: `${lkBaseUrl}/project/dz`,
	agreementInfo: `${loanBaseUrl}/projects/project/{projectId}/counterparty/agreements/agreement/{agreementId}/info`,
	
	getLkExpertiseUrl: (projectId: string,
		expertiseId: string) => `${lkBaseUrl}/project/expert-status/${projectId}/expertise/${expertiseId}`,
	
	help: "https://lkfrprf.bitrix24.site/tranches_gtqz/"
};
