import { Agreement, AgreementHelper } from "@/store/tranches/modules/application/types/agreement";
import { ApiBorrowerOrganization, ApiBorrowerOrganizationHelper } from "@/api/tranches/types/apiBorrowerOrganization";
import { ApiProjectInfo } from "@/api/tranches/types/apiProjectInfo";
import { CounterpartyAgreement, CounterpartyAgreementMapper } from "@/types/tranches/counterpartyAgreement";
import { sortBy } from "lodash";

export interface ProjectInfo {
	id: number;
	loanId: number;
	name: string;
	applicationNumber: number;
	agreement: Agreement;
	borrowerOrganization: ApiBorrowerOrganization;
	counterpartyAgreements: CounterpartyAgreement[];
}

export class ProjectInfoHelper {
	static map(source: ApiProjectInfo): ProjectInfo {
		return {
			...source,
			agreement: AgreementHelper.map(source.agreement),
			counterpartyAgreements: sortBy(source.counterpartyAgreements.map(x => CounterpartyAgreementMapper.map(x)), "date")
			.filter(x => x.isActive)
		};
	}
	
	static getEmpty(): ProjectInfo {
		return {
			id: 0,
			loanId: 0,
			name: "",
			applicationNumber: 0,
			agreement: AgreementHelper.getEmpty(),
			borrowerOrganization: ApiBorrowerOrganizationHelper.getEmpty(),
			counterpartyAgreements: []
		};
	}
}