import IPageState from "@/store/shared/base/types/pageState";
import { TrancheScheduleModeTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleModeTypeEnum";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { ProjectInfo, ProjectInfoHelper } from "@/store/tranches/modules/application/types/projectInfo";
import { TrancheSchedule, TrancheScheduleHelper } from "@/store/tranches/modules/application/types/trancheSchedule";
import { TrancheScheduleTranche } from "@/store/tranches/modules/application/types/trancheScheduleTranche";
import { TrancheScheduleHistory } from "@/types/tranches/trancheScheduleHistory";
import { Expertise } from "@/types/expertise/expertise";
import { ExpertiseSubType } from "@/types/expertise/expertiseSubType";
import { ExpertiseType } from "@/types/expertise/expertiseType";

export default class TranchesTrancheApplicationState implements IPageState {
	constructor(
		public listing: ListingModel<TrancheScheduleTranche>,
		public sorting: SortingModel<String>,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isTrancheScheduleSaving: boolean = false,
		public isTrancheScheduleOrderConditionsSaving: boolean = false,
		public isTrancheScheduleFormValid: boolean = false,
		public sendingToApprovalTrancheNumber?: number,
		public isOrderTranchePaymentApplicationCreating: boolean = false,
		public applicationNumber: string = "",
		public isProjectInfoFetching: boolean = false,
		public projectInfo: ProjectInfo = ProjectInfoHelper.getEmpty(),
		public trancheSchedule: TrancheSchedule = TrancheScheduleHelper.getEmpty(),
		public acceptingTrancheNumber?: number,
		public rejectingTrancheNumber?: number,
		public confirmingTrancheNumber?: number,
		public isDocumentUploading: boolean = false,
		public isTrancheAgreementsSaving: boolean = false,
		public unacceptingTrancheNumber?: number,
		public deletingDocumentIds: string[] = [],
		public mode: TrancheScheduleModeTypeEnum = TrancheScheduleModeTypeEnum.READ,
		public editableItems: TrancheScheduleTranche[] = [],
		public trancheScheduleSum: number = 0,
		public currentDialogTrancheNumber: number | undefined = undefined,
		public isTrancheScheduleUnsavedChangesDialogEnabled: boolean = false,
		public history: TrancheScheduleHistory = {} as TrancheScheduleHistory,
		public expertises: Expertise[] = [],
		public expertiseSubTypes: ExpertiseSubType[] = [],
		public expertiseTypes: ExpertiseType[] = [],
		public canViewExpertises: boolean = false,
		public isExpertisesLoading: boolean = false,
		public isExpertiseCreating: boolean = false,
		public trancheOrderFile?: Blob,
		public trancheOrderFilename?: string,
	)
	{
	}
}